<template>
  <el-dropdown :key="userImg" @command="handleCommand">
    <el-avatar icon="el-icon-user-solid" :src="userImg"></el-avatar>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="home">文档首页</el-dropdown-item>
      <el-dropdown-item command="profile">个人中心</el-dropdown-item>
      <el-dropdown-item command="logout">退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { dealOtherError } from '@/api/error'
import api from '@/api'

export default {
  data () {
    return {
      userImg: ''
    }
  },
  async mounted () {
    try {
      ({ data: { userInfo: { avatar_link: this.userImg } } } = await api.getUserSelfInfo())
    } catch (error) {
      console.log('error:', error)
      dealOtherError(this, error)
    }
  },
  methods: {
    handleCommand (command) {
      console.log('menu command: ', command)
      switch (command) {
        case 'logout': this.logout(); break
        case 'profile': this.$router.push({ name: 'Profile' }); break
        case 'home': this.$router.push({ name: 'Home' })
      }
    },
    logout: async function () {
      console.log('logout')
      try {
        await api.logout()
        await this.$router.push({ name: 'Login' })
      } catch (error) {
        dealOtherError(this, error)
      }
    },
    updateAvatar (link) {
      this.userImg = link
    }
  }
}
</script>
