<template>
  <div style="width: 10px"></div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
