<template>
  <div style="height: 100%">
    <base-row class="header-container">
      <base-row justify="start">
        <el-button class="back-button" icon="el-icon-back" @click="goBack"></el-button>
        <div style="width: 5px"></div>
        <slot name="left" />
      </base-row>
      <base-row justify="end">
        <slot name="right" />
        <BaseAvatarSelf v-if="containAvatar" ref="avatar" />
        <BaseLogo v-else />
      </base-row>
    </base-row>
  </div>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import BaseAvatarSelf from '@/components/BaseAvatarSelf'

export default {
  components: {
    BaseLogo,
    BaseAvatarSelf
  },
  props: {
    backPath: {
      type: String,
      default: ''
    },
    containAvatar: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goBack () {
      if (this.backPath === '') this.$router.back()
      else this.$router.push({ path: this.backPath })
    }
  }
}
</script>

<style scoped>
.header-container {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 1px 1px, rgba(0, 0, 0, 0.1) 0 2px 4px;
  position: relative;
  z-index: 100;
}
.back-button {
  padding: 10px;
  border: 0;
}
</style>
