<template>
  <h1 class="logo">在线协作文档</h1>
</template>

<script>
export default {}
</script>

<style scoped>
.logo {
  font-family: monospace;
  min-height: 22px;
  font-size: 20px;
}
</style>
