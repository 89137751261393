import validator from '@/validate/validator'

export default {
  username: [
    { validator: validator.username }
  ],
  email: [
    { validator: validator.isEmptyEmail },
    { type: 'email', message: '邮箱格式错误' }
  ],
  password: [
    { validator: validator.password }
  ],
  verifyCode: [
    { validator: validator.verifyCode }
  ]
}
