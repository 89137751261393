<template>
  <base-row>
    <base-row>
      <el-input :value="verifyCode" @input="handleInput" />
    </base-row>
    <BaseSeparationColumn />
    <base-row style="width: 150px">
      <el-button
        class="button"
        @click="onSendVerifyCode"
        :loading="loading"
        :disabled="isSendVerifyCode"
      >
        {{ messageSendVerifyCode }}
      </el-button>
    </base-row>
  </base-row>
</template>

<script>
import BaseSeparationColumn from '@/components/BaseSeparationColumn'
import api from '@/api'
import { dealOtherError } from '@/api/error'
import { APICode } from '@/api/common'

const defaultMessageSendVerifyCode = '获取验证码'
const retryMessageSendVerifyCode = '重新发送'
const intervalTimeSendVerifyCode = 60

export default {
  components: {
    BaseSeparationColumn
  },
  model: {
    prop: 'verifyCode'
  },
  props: {
    verifyCode: String,
    email: String
  },
  data () {
    return {
      loading: false,
      isSendVerifyCode: false,
      messageSendVerifyCode: defaultMessageSendVerifyCode,
      leftTimeSendVerifyCode: intervalTimeSendVerifyCode,
      intervalIDSendVerifyCode: 0
    }
  },
  methods: {
    async onSendVerifyCode () {
      if (this.email === '') {
        this.$message.error('邮箱为空')
        return
      }
      try {
        this.loading = true
        await api.sendVerifyCode('email', this.email)
        this.$message.success('发送验证码成功')
        this.isSendVerifyCode = true
        this.intervalIDSendVerifyCode = setInterval(this.handleSendVerifyCodeCounter, 1200)
      } catch (error) {
        this.messageSendVerifyCode = retryMessageSendVerifyCode
        if (error.resp) {
          const resp = error.resp
          if (resp.code === APICode.Limit) {
            this.$message.error('发送次数频繁，请稍后重新发送')
            return
          }
        }
        dealOtherError(this, error)
      } finally {
        this.loading = false
      }
    },
    handleSendVerifyCodeCounter () {
      if (!this.isSendVerifyCode) return
      if (this.leftTimeSendVerifyCode === 0) {
        this.messageSendVerifyCode = defaultMessageSendVerifyCode
        this.isSendVerifyCode = false
        this.leftTimeSendVerifyCode = intervalTimeSendVerifyCode
        clearInterval(this.intervalIDSendVerifyCode)
        return
      }
      this.messageSendVerifyCode = '还剩' + this.leftTimeSendVerifyCode.toString() + '秒'
      this.leftTimeSendVerifyCode--
    },
    handleInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.button {
  flex-grow: 1;
}
</style>
