const username = function (value) {
  if (value === '') {
    return new Error('请输入用户名')
  } else if (value.length < 4) {
    return new Error('用户名太短')
  } else if (value.length > 16) {
    return new Error('用户名太长')
  } else if (!/^[a-zA-Z]/.test(value)) {
    return new Error('首字符必须为字母')
  } else if (!/^[a-zA-Z0-9_]*$/.test(value)) {
    return new Error('用户名只能包含字符和数字')
  } else {
    return null
  }
}

const password = function (value) {
  if (value === '') {
    return new Error('请输入密码')
  } else if (value.length < 8) {
    return new Error('密码太短')
  } else if (value.length > 20) {
    return new Error('密码太长')
  } else if (!/^[\x20-\x7E]*$/.test(value)) {
    return new Error('密码包含非法字符')
  } else if (/\s/.test(value)) {
    return new Error('密码包含空白字符')
  } else {
    return null
  }
}

const verifyCode = function (value) {
  if (value === '') {
    return new Error('请输入验证码')
  } else if (!/^[0-9]*$/.test(value)) {
    return new Error('验证码应为纯数字')
  } else if (value.length !== 6) {
    return new Error('验证码长度错误')
  } else {
    return null
  }
}

const isEmptyEmail = function (value) {
  if (value === '') {
    return new Error('请输入邮箱')
  } else {
    return null
  }
}

const avatarFile = function ({ type, size }) {
  switch (type) {
    case 'image/jpeg': break
    case 'image/png': break
    default: return new Error('头像仅支持jpg和png格式！')
  }
  if (size > (2 << 20)) {
    return new Error('头像图片大小超过2MB！')
  }
  return null
}

export default {
  isEmptyEmail,
  username,
  password,
  verifyCode,
  avatarFile
}
