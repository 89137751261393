import validate from '@/validate/validate'

function constructValidator () {
  const validator = {}
  Object.keys(validate).forEach(key => {
    validator[key] = function (rule, value, callback) {
      const error = validate[key](value)
      if (error) callback(error)
      else callback()
    }
  })
  return validator
}

export default constructValidator()
